import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://grandmodafash.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://grandmodafash.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://grandmodafash.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://grandmodafash.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://grandmodafash.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://grandmodafash.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://grandmodafash.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 90px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="60%"
					padding="80px 80px 80px 80px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" color="#E65455">
						Фотостудія
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 600 72px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
						PicturePerfect{" "}
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
						Ласкаво просимо до місця, де кожен знімок розповідає історію. Незалежно від того, чи хочете ви закарбувати відверті спогади, професійні портрети або творчі сцени, наша студія забезпечить ідеальне тло для ваших фотографічних прагнень. Завдяки команді пристрасних фотографів та найсучаснішим технологіям, ми робимо кожну сесію незабутньою.
					</Text>
					<Button
						background="#E65455"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--lead"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#E65455"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contact-us"
						type="link"
						text-decoration-line="initial"
					>
						Звʼязатися з нами
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="40%"
					justify-content="flex-end"
					align-items="center"
					md-margin="-120px 0px 0px 0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://grandmodafash.com/images/1.jpg"
						display="block"
						margin="0px -100px 0px 0px"
						height="100%"
						md-margin="0px 0 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section background="#f9f2f0" padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://grandmodafash.com/images/2.jpg"
					display="block"
					width="30%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
					object-position="0%"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="70%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Чому обирають нас
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(3, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet">
									Професійна якість
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Зображення з високою роздільною здатністю, які захоплюють кожну деталь.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet">
									Персоналізований досвід
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Індивідуальні сесії, які відповідають вашому унікальному стилю та вподобанням.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet">
									Творча свобода
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								Різноманітні тематичні фони та реквізит для розкриття вашого творчого потенціалу.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-15">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 35px 0px"
					sm-flex-direction="column"
				>
					<Image
						src="https://grandmodafash.com/images/3.jpg"
						display="block"
						object-fit="cover"
						margin="0px 5px 0px 0px"
						width="26%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://grandmodafash.com/images/4.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://grandmodafash.com/images/5.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0px 0px 0px 0"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
					text-align="center"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="left">
						Готові заморозити свої особливі моменти в часі? Зверніться до PicturePerfect Studio вже сьогодні, і ми допоможемо вам створити спогади, які залишаться на все життя.
					</Text>
					<Text
						margin="70px 0px 0px 0px"
						font="normal 300 28px/1.5 --fontFamily-sans"
						color="#474e53"
						text-align="center"
						width="100%"
					>
						Завітайте до нас сьогодні, щоб розпочати свою фотографічну подорож!{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});